import download from "downloadjs"

export default class SocialLinkCrawlerDownload {

    constructor() {
        this.queue_id = document.getElementById('queue_id')
        this.download_btn = document.getElementById('download_queue')

        this.events()
    }

    events() {
        this.download_btn.addEventListener('click', async e => {
            this.download_btn.setAttribute('disabled', true)

            this.csvData = [
                ['Id', 'Website', 'Instagram', 'Youtube', 'LinkedIn', 'Twitter', 'Facebook', 'Pinterest', 'TikTok'].join(',')
            ]

            await axios.get(`/crawler/social-link/download/${this.queue_id.value}`).then(res => {
                res.data.forEach(element => {
                    this.csvData.push([
                        element.id,
                        element.website,
                        element.instagram_accounts,
                        element.youtube_accounts,
                        element.linkedin_accounts,
                        element.twitter_accounts,
                        element.facebook_accounts,
                        element.pinterest_accounts,
                        element.tiktok_accounts,
                    ].join(','))
                });
            }).catch(err => {
            })
            download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");

            setTimeout(() => {
                this.download_btn.removeAttribute('disabled')
            }, 5000);
        })
    }

}
