import download from "downloadjs"

export default class InstagramCrawlerDownload {

    constructor() {
        this.queue_id = document.getElementById('queue_id')
        this.download_btn = document.getElementById('download_queue')

        this.events()
    }

    events() {
        this.download_btn.addEventListener('click', async e => {
            this.download_btn.setAttribute('disabled', true)

            this.csvData = [
                ['URL', 'Bio', 'Website', 'Followers', 'Follows', 'Posts', 'Hashtags', 'Most Hashtag', 'Average Likes', 'Average Commnets'].join(',')
            ]

            await axios.get(`/crawler/instagram/download/${this.queue_id.value}`).then(res => {
                res.data.forEach(element => {
                    console.log(element)
                    this.csvData.push([
                        (element.url) ? element.url : '-',
                        element.biography ? `"${element.biography}"`.replaceAll('\n', ' ') : '-',
                        element.bio_website ? `"${element.bio_website}"` : '-',
                        element.followers ? element.followers : '',
                        element.follows ? element.follows : '-',
                        element.posts ? element.posts : '-',
                        element.hashtags ? `"${element.hashtags}"` : '',
                        element.most_hashtags ? `"${element.most_hashtags}"` : '-',
                        element.avg_likes ? element.avg_likes : '-',
                        element.avg_comments ? element.avg_comments : '-',
                    ].join(','))
                });
            }).catch(err => {
            })

            download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");

            setTimeout(() => {
                this.download_btn.removeAttribute('disabled')
            }, 5000);
        })
    }

}
