import download from "downloadjs"

export default class SslCodeCrawler {

    constructor() {
        this.itemList = $('#ssl-codes-index')

        this.events()
    }


    events(){
        console.log(this.itemList)
        this.itemList.on('click', '[data-type="download"]', e => {
            let id = $(e.currentTarget).data('id')
            this.download(id)
        })

    }

    async download(id) {
        this.csvData = [
            ['Id', 'Website', 'Response Code', 'SSL Status'].join(',')
        ]

        await axios.get(`/crawler/ssl-code/download/${id}`).then(res => {
            res.data.forEach(element => {
                this.csvData.push([
                    element.id,
                    element.website,
                    element.response_code,
                    element.ssl_human
                ].join(','))
            });
        }).catch(err => {
        })
        download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");
    }



}
