import axios from "axios"
import download from "downloadjs"
import downloadURI from "downloadjs"

export default class LogoCrawlerDownload {

    constructor() {
        this.queue_id = document.getElementById('queue_id')
        this.download_btn = document.getElementById('download_queue')

        this.events()
    }

    events() {
        this.download_btn.addEventListener('click', async e => {
            this.download_btn.setAttribute('disabled', true)

            this.csvData = [
                ['Id', 'Website', 'Logo Url'].join(',')
            ]

            await axios.get(`/crawler/logo/download/${this.queue_id.value}`).then(res => {
                download(res.data, 'Logos.zip', 'application/zip')
            }).catch(err => {
            })

            setTimeout(() => {
                this.download_btn.removeAttribute('disabled')
            }, 5000);
        })
    }

}
