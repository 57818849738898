import download from "downloadjs"

export default class InstagramCrawlerCreate {

    constructor() {

        this.csvData = []

        this.crawlerName = document.getElementById('crawler-name')

        this.igUrlField = document.getElementById('ig-url')
        this.igUrlBulkField = document.getElementById('ig-url-text')
        this.addUrlBtn = document.getElementById('add-ig-btn')
        this.addUrlBulkBtn = document.getElementById('add-ig-bulk-btn')

        this.igNamesGroup = document.getElementById('ig-list')

        this.runCrawlerBtn = document.getElementById('run-crawler-btn')
        this.crawlerItems = document.getElementById('instagram-crawler-items')

        this.events()
    }

    events() {
        this.addUrlBtn.addEventListener('click', e => this.addUrl(e))
        this.addUrlBulkBtn.addEventListener('click', e => this.addUrlBulk(e))

        this.runCrawlerBtn.addEventListener('click', e => this.runCrawler(e))
        this.crawlerItems.addEventListener('click', e => this.crawlerItemsClick(e))
    }

    crawlerItemsClick(e) {
        let element = e.target;
        if(element.dataset.action && element.dataset.action == "remove") {
            element = element.closest('li').remove();
        }
    }

    generateListItem(item_name) {
        return `
            <li class="list-group-item d-flex justify-content-between" data-name="${item_name}">
                <div>
                    <span>${item_name}</span>
                </div>
                <div>
                    <button class="remove-item" data-action="remove">X</button>
                </div>
            </li>
        `;
    }
    generateURLListItem(item_name) {
        let data_name = item_name;
        // if(!item_name.startsWith('http') || !item_name.startsWith('https')) {
        //     data_name = 'https://' + item_name
        // }
        return `
            <li class="list-group-item d-flex justify-content-between" data-name="${data_name}">
                <div>
                    <span>${item_name}</span>
                </div>
                <div>
                    <button class="remove-item" data-action="remove">X</button>
                </div>
            </li>
        `;
    }

    addUrl(e) {
        if(this.igUrlField.value.length > 0) {
            this.igNamesGroup.insertAdjacentHTML('beforeend', this.generateListItem(this.igUrlField.value))
            this.igUrlField.value = ''
        }
    }
    addUrlBulk(e) {
        if(this.igUrlBulkField.value.length > 0) {
            let keyword_items = this.igUrlBulkField.value.replace(/\n/g, ',').split(',')
            keyword_items.forEach(keyword_item => {
                this.igNamesGroup.insertAdjacentHTML('beforeend', this.generateListItem(keyword_item))
                this.igUrlBulkField.value = ''
            })
        }
    }

    addBlacklist(e) {
        if(this.blacklistField.value.length > 0) {
            this.blacklistGroup.insertAdjacentHTML('beforeend', this.generateListItem(this.blacklistField.value))
            this.blacklistField.value = ''
        }
    }
    addBlacklistBulk(e) {
        if(this.blacklistText.value.length > 0) {
            let black_list_items = this.blacklistText.value.replace(/\n/g, ',').split(',')
            black_list_items.forEach(black_list_item => {
                this.blacklistGroup.insertAdjacentHTML('beforeend', this.generateListItem(black_list_item))
                this.blacklistText.value = ''
            })
        }
    }

    async runCrawler(e) {
        let urls = document.querySelectorAll('#ig-list li')

        urls = Array.from(urls).map(el => el.dataset.name)

        if(urls.length == 0 ) {
            alert('Please add at least 1 ore more urls.');
            return false;
        }

        if(this.crawlerName.value.length == 0) {
            alert('Please add crawler queue name.');
            return false
        }

        try {
            await axios.post(`/crawler/instagram`, {
                ig_list: urls,
                name: this.crawlerName.value
            }).then(res => {
                if(res.data.redirect) {
                    window.location.assign(res.data.redirect)
                } else {
                    window.location.assign('/crawler?crawler_status=Crawling%20list%20has%20been%20added%20in%20the%20queue.')
                }
            }).catch(err => {
                console.error(err)
            })
        } catch (error) {
            console.error(error)
        } finally {
        }
    }

}
