import download from "downloadjs"

export default class SslCodeCrawlerDownload {

    constructor() {
        this.queue_id = document.getElementById('queue_id')
        this.download_btn = document.getElementById('download_queue')

        this.events()
    }

    events() {
        this.download_btn.addEventListener('click', async e => {
            this.download_btn.setAttribute('disabled', true)

            this.csvData = [
                ['Id', 'Website', 'Response Code', 'SSL Status'].join(',')
            ]

            await axios.get(`/crawler/ssl-code/download/${this.queue_id.value}`).then(res => {
                res.data.forEach(element => {
                    this.csvData.push([
                        element.id,
                        element.website,
                        element.response_code,
                        element.ssl_human
                    ].join(','))
                });
            }).catch(err => {
            })
            download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");

            setTimeout(() => {
                this.download_btn.removeAttribute('disabled')
            }, 5000);
        })
    }

}
