import download from "downloadjs"

export default class InstagramCrawler {

    constructor() {
        this.itemList = $('#instagram-index')

        this.events()
    }


    events(){
        console.log(this.itemList)
        this.itemList.on('click', '[data-type="download"]', e => {
            let id = $(e.currentTarget).data('id')
            this.download(id)
        })

    }

    async download(id) {
        this.csvData = [
            ['URL', 'Bio', 'Website', 'Followers', 'Follows', 'Posts', 'Hashtags', 'Most Hashtag', 'Average Likes', 'Average Commnets'].join(',')
        ]

        await axios.get(`/crawler/instagram/download/${id}`).then(res => {
            res.data.forEach(element => {
                this.csvData.push([
                    element.ig_url,
                    `"${element.biography}"`.replaceAll('\n', ' '),
                    `"${element.bio_website}"`,
                    element.followers,
                    element.follows,
                    element.posts,
                    `"${element.hashtags}"`,
                    `"${element.most_hashtags}"`,
                    element.avg_likes,
                    element.avg_comments,
                ].join(','))
            });
        }).catch(err => {
        })

        download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");
    }



}
