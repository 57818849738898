import download from "downloadjs"

export default class CrawlerPageCreate {

    constructor() {

        this.csvData = []

        this.keywordNameField = document.getElementById('keyword-name')
        this.keywordNameText = document.getElementById('keyword-name-text')
        this.urlField = document.getElementById('url-name')
        this.urlTexts = document.getElementById('url-name-text')
        this.blacklistField = document.getElementById('blacklist-name')
        this.blacklistText = document.getElementById('blacklist-name-text')
        this.addKeywordNameBtn = document.getElementById('add-keyword-btn')
        this.addKeywordBulkBtn = document.getElementById('add-keyword-bulk-btn')
        this.addUrlBtn = document.getElementById('add-url-btn')
        this.addUrlBulkBtn = document.getElementById('add-url-bulk-btn')
        this.addBlacklistBtn = document.getElementById('add-blacklist-btn')
        this.addBlacklistBulkBtn = document.getElementById('add-blacklist-bulk-btn')

        this.crawlerName = document.getElementById('crawler-name')

        this.keywordNameGroup = document.getElementById('keywords-list-group')
        this.urlGroup = document.getElementById('url-list-group')
        this.blacklistGroup = document.getElementById('blacklist-list-group')

        this.runCrawlerBtn = document.getElementById('run-crawler-btn')
        this.crawlerItems = document.getElementById('crawler-items')

        this.crawlerStatusCard = document.getElementById('crawler-status')


        this.statsCawlerStatus = document.getElementById('crawler_status')
        this.crawlerStatusInterval = null;

        this.statsQueuedLinks = document.getElementById('stat-queued-links')
        this.statsRunningLinkCount = document.getElementById('stat-running-links')
        this.statsSuccededCount = document.getElementById('stat-succeded-links')
        this.statsFailedCount = document.getElementById('stat-failed-links')
        this.statsResponseCount = document.getElementById('stat-response-links')


        this.downloadCurrentDataBtn = document.getElementById('download-current-data')
        this.events()
    }

    events() {
        this.addKeywordNameBtn.addEventListener('click', e => this.addKeywordName(e))
        this.addKeywordBulkBtn.addEventListener('click', e => this.addKeywordBulk(e))
        this.addUrlBtn.addEventListener('click', e => this.addURLName(e))
        this.addUrlBulkBtn.addEventListener('click', e => this.addUrlBulk(e))
        this.addBlacklistBtn.addEventListener('click', e => this.addBlacklist(e))
        this.addBlacklistBulkBtn.addEventListener('click', e => this.addBlacklistBulk(e))

        this.downloadCurrentDataBtn.addEventListener('click', e => this.downloadCurrentDataToCsv(e))

        this.runCrawlerBtn.addEventListener('click', e => this.runCrawler(e))
        this.crawlerItems.addEventListener('click', e => this.crawlerItemsClick(e))
    }

    crawlerItemsClick(e) {
        let element = e.target;
        if(element.dataset.action && element.dataset.action == "remove") {
            element = element.closest('li').remove();
        }
    }

    generateListItem(item_name) {
        return `
            <li class="list-group-item d-flex justify-content-between" data-name="${item_name}">
                <div>
                    <span>${item_name}</span>
                </div>
                <div>
                    <button class="remove-item" data-action="remove">X</button>
                </div>
            </li>
        `;
    }
    generateURLListItem(item_name) {
        let data_name = item_name;
        // if(!item_name.startsWith('http') || !item_name.startsWith('https')) {
        //     data_name = 'https://' + item_name
        // }
        return `
            <li class="list-group-item d-flex justify-content-between" data-name="${data_name}">
                <div>
                    <span>${item_name}</span>
                </div>
                <div>
                    <button class="remove-item" data-action="remove">X</button>
                </div>
            </li>
        `;
    }

    addKeywordName(e) {
        if(this.keywordNameField.value.length > 0) {
            this.keywordNameGroup.insertAdjacentHTML('beforeend', this.generateListItem(this.keywordNameField.value))
            this.keywordNameField.value = ''
        }
    }
    addKeywordBulk(e) {
        if(this.keywordNameText.value.length > 0) {
            let keyword_items = this.keywordNameText.value.replace(/\n/g, ',').split(',')
            keyword_items.forEach(keyword_item => {
                this.keywordNameGroup.insertAdjacentHTML('beforeend', this.generateListItem(keyword_item))
                this.keywordNameText.value = ''
            })
        }
    }

    addURLName(e) {
        if(this.urlField.value.length > 0) {
            this.urlGroup.insertAdjacentHTML('beforeend', this.generateListItem(this.urlField.value))
            this.urlField.value = ''
        }
    }

    addUrlBulk(e) {
        if(this.urlTexts.value.length > 0) {
            let url_items = this.urlTexts.value.replace(/\n/g, ',').split(',')
            url_items.forEach(url_item => {
                this.urlGroup.insertAdjacentHTML('beforeend', this.generateURLListItem(url_item))
                this.urlTexts.value = ''
            })
        }
    }

    addBlacklist(e) {
        if(this.blacklistField.value.length > 0) {
            this.blacklistGroup.insertAdjacentHTML('beforeend', this.generateListItem(this.blacklistField.value))
            this.blacklistField.value = ''
        }
    }
    addBlacklistBulk(e) {
        if(this.blacklistText.value.length > 0) {
            let black_list_items = this.blacklistText.value.replace(/\n/g, ',').split(',')
            black_list_items.forEach(black_list_item => {
                this.blacklistGroup.insertAdjacentHTML('beforeend', this.generateListItem(black_list_item))
                this.blacklistText.value = ''
            })
        }
    }

    downloadCurrentDataToCsv(e) {
        download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");
    }

    async runCrawler(e) {
        let keywords = document.querySelectorAll('#keywords-list-group li')
        let urls = document.querySelectorAll('#url-list-group li')
        let blacklists = document.querySelectorAll('#blacklist-list-group li')

        keywords = Array.from(keywords).map(el => el.dataset.name)
        urls = Array.from(urls).map(el => el.dataset.name)
        blacklists = Array.from(blacklists).map(el => el.dataset.name)
        this.csvData = [
            ['Keyword', 'Main URL', 'First found at'].join(',')
        ]

        if(urls.length == 0 ) {
            alert('Please add at least 1 ore more urls.');
            return false;
        }

        if(this.crawlerName.value.length == 0) {
            alert('Please add crawler queue name.');
            return false
        }

        if(keywords.length == 0) {
            alert('Please add at least 1 keyword.');
        }

        try {
            await axios.post(`/crawler`, {
                keyword: keywords,
                urls: urls,
                blacklist: blacklists,
                name: this.crawlerName.value
            }).then(res => {
                if(res.data.redirect) {
                    window.location.assign(res.data.redirect)
                } else {
                    window.location.assign('/crawler?crawler_status=Crawling%20list%20has%20been%20added%20in%20the%20queue.')
                }
            }).catch(err => {
                console.error(err)
            })
        } catch (error) {
            console.error(error)
        } finally {
        }
    }

}
