import download from "downloadjs"

export default class CrawlerPage {

    constructor() {
        this.itemList = $('#crawlena-index')
        console.log("loaded")
        this.events()
    }


    events(){
        console.log(this.itemList)
        this.itemList.on('click', '[data-type="download"]', e => {
            let id = $(e.currentTarget).data('id')
            this.download(id)
        })

    }

    async download(id) {
        this.csvData = [
            ['Keyword', 'Main URL', 'First found at', 'status'].join(',')
        ]

        await axios.get(`/crawler/download/${id}`).then(res => {
            if(res.data.queue_url) {
                res.data.queue_url.forEach(element => {
                    if(element.crawler_url_items.length) {
                        element.crawler_url_items.forEach(el => {
                            this.csvData.push([el.keyword, el.main_url, el.first_found_at, element.status_human].join(','))
                        })
                    } else {
                        this.csvData.push(["-", element.url, '-', element.status_human].join(','))
                    }
                });
            }
        }).catch(err => {
            console.error(err.message)
        })

        download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");
    }



}
