import download from "downloadjs"

export default class CrawlerDownload {

    constructor() {
        this.queue_id = document.getElementById('queue_id')
        this.download_btn = document.getElementById('download_queue')

        this.events()
    }

    events() {
        this.download_btn.addEventListener('click', async e => {
            this.download_btn.setAttribute('disabled', true)

            this.csvData = [
                ['Keyword', 'Main URL', 'First found at', 'status'].join(',')
            ]

            console.log("downloading")

            await axios.get(`/crawler/download/${this.queue_id.value}`).then(res => {
                if(res.data.queue_url) {
                    res.data.queue_url.forEach(element => {
                        if(element.crawler_url_items.length) {
                            element.crawler_url_items.forEach(el => {
                                this.csvData.push([el.keyword, el.main_url, el.first_found_at, element.status_human].join(','))
                            })
                        } else {
                            this.csvData.push(["-", element.url, '-', element.status_human].join(','))
                        }
                    });
                }
            }).catch(err => {
                console.error(err.message)
            })

            download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");

            setTimeout(() => {
                this.download_btn.removeAttribute('disabled')
            }, 5000);
        })
    }

}
