import axios from "axios"

export default class ImageConverterCreate{

    constructor() {
        this.convertButton = document.getElementById('create-image-converter-btn')

        this.width = document.getElementById('width')
        this.height = document.getElementById('height')
        this.images = document.getElementById('images')
        this.type = document.getElementById('type')

        this.events()
    }

    events() {
        this.convertButton.addEventListener('click', e => {
            this.convertButton.setAttribute('disabled', true)

            let width = parseFloat(this.width.value)
            let height = parseFloat(this.height.value)
            if(isNaN(width) || width < 10) {
                return this.reset('Image width must be higher than 10px')
            }
            if(isNaN(height) || height < 10) {
                return this.reset('Image height must be higher than 10px')
            }
            if(this.images.files.length == 0) {
                return this.reset('Please select a file')
            }

            let form = new FormData()
            form.append('width', width);
            form.append('height', height);
            form.append('images', this.images.files[0]);
            form.append('type', this.type.value);

            axios.post('/tools/image-converter', form).then(res => {
                if(res.data.redirect) {
                    location.assign(res.data.redirect)
                }
                location.assign('/tools/image-converter')
            }).catch(err => {
                console.log(err.response.data)
            }).finally(() => {
                return this.reset()
            })

        })
    }

    reset(msg) {
        if(msg && msg.length) {
            alert(msg)
        }
        this.convertButton.removeAttribute('disabled')
    }

}
