require('./bootstrap');
import CrawlerPage from './Crawler/CrawlerPage/CrawlerPage';
import CrawlerDownload from './Crawler/CrawlerPage/CrawlerDownload';
import CrawlerPageCreate from './Crawler/CrawlerPage/CrawlerPageCreate'
import InstagramCrawler from './Crawler/Instagram/InstagramCrawler';
import InstagramCrawlerCreate from './Crawler/Instagram/InstagramCrawlerCreate';
import InstagramCrawlerDownload from './Crawler/Instagram/InstagramCrawlerDownload';
import LogoCrawlerCreate from './Crawler/Logo/LogoCrawlerCreate';
import SocialLinkCrawlerCreate from './Crawler/Social-Links/SocialLinkCrawlerCreate';
import LogoCrawlerDownload from './Crawler/Logo/LogoCrawlerDownload';
import SocialLink from './Crawler/Social-Links/SocialLinkCrawler';
import SocialLinkCrawlerDownload from './Crawler/Social-Links/SocialLinkCrawlerDownload';
import SocialAnalyticCrawlerDownload from './Crawler/Social-Analytics/SocialAnalyticCrawlerDownload';
import SocialAnalyticCrawlerCreate from './Crawler/Social-Analytics/SocialAnalyticCrawlerCreate';
import SslCodeCrawler from './Crawler/ssl-codes/SslCodeCrawler';
import SslCodeCrawlerCreate from './Crawler/ssl-codes/SslCodeCrawlerCreate';
import SslCodeCrawlerDownload from './Crawler/ssl-codes/SslCodeCrawlerDownload';
import ColorPalette from './Crawler/color-palette/ColorPalette';
import ColorPaletteCreate from './Crawler/color-palette/ColorPaletteCreate';
import ColorPaletteDownload from './Crawler/color-palette/ColorPaletteDownload';
import LogoCrawler from './Crawler/Logo/LogoCrawler';
import SocialAnalytic from './Crawler/Social-Analytics/SocialAnalytic';
import ImageConverterCreate from './Tools/ImageConverter/Create';


// crawlers
if(document.getElementById('crawlena-index')) new CrawlerPage()
if(document.getElementById('crawler-container')) new CrawlerPageCreate()
if(document.getElementById('instagram-index')) new InstagramCrawler()
if(document.getElementById('instagram-crawler-container')) new InstagramCrawlerCreate()
if(document.getElementById('logo-crawler-container')) new LogoCrawlerCreate()
if(document.getElementById('social-links-index')) new SocialLink()
if(document.getElementById('socialLink-crawler-container')) new SocialLinkCrawlerCreate()
if(document.getElementById('socialAnalytic-crawler-container')) new SocialAnalyticCrawlerCreate()
if(document.getElementById('ssl-codes-index')) new SslCodeCrawler()
if(document.getElementById('sslCode-crawler-container')) new SslCodeCrawlerCreate()
if(document.getElementById('colorPalette-crawler-container')) new ColorPaletteCreate()
if(document.getElementById('download-crawler-page')) new CrawlerDownload()
if(document.getElementById('download-instagram-crawler-page')) new InstagramCrawlerDownload()
if(document.getElementById('download-logo-crawler-page')) new LogoCrawlerDownload()
if(document.getElementById('socialLink-instagram-crawler-page')) new SocialLinkCrawlerDownload()
if(document.getElementById('color-palette-index')) new ColorPalette()
if(document.getElementById('colorPalette-crawler-page')) new ColorPaletteDownload()
if(document.getElementById('social-analytics-index')) new SocialAnalytic()
if(document.getElementById('socialAnalytic-instagram-crawler-page')) new SocialAnalyticCrawlerDownload()
if(document.getElementById('sslCode-instagram-crawler-page')) new SslCodeCrawlerDownload()

// tools
if(document.getElementById('tools.image-converter.create')) new ImageConverterCreate()
