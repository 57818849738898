import download from "downloadjs"

export default class ColorPaletteDownload {

    constructor() {
        this.queue_id = document.getElementById('queue_id')
        this.download_btn = document.getElementById('download_queue')

        this.events()
    }

    events() {
        this.download_btn.addEventListener('click', async e => {
            this.download_btn.setAttribute('disabled', true)

            this.csvData = [
                [
                    'domain',
                    'Color 1',
                    'Matches',
                    'Color 2',
                    'Matches',
                    'Color 3',
                    'Matches',
                    'Color 4',
                    'Matches',
                    'Color 5',
                    'Matches'
                ].join(',')
            ]

            await axios.get(`/crawler/color-palette/download/${this.queue_id.value}`).then(res => {
                for (var key of Object.keys(res.data)) {
                    let item = [];
                    item.push(res.data[key][0].domain)
                    res.data[key].forEach(element => {
                        item.push(element.color_code)
                        item.push(element.matches)
                    });
                    this.csvData.push(item.join(','))
                }
            }).catch(err => {
                console.error(err)
            })
            download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");

            setTimeout(() => {
                this.download_btn.removeAttribute('disabled')
            }, 5000);
        })
    }

}
