import download from "downloadjs"

export default class ColorPalette {

    constructor() {
        this.itemList = $('#social-links-index')

        this.events()
    }


    events(){
        console.log(this.itemList)
        this.itemList.on('click', '[data-type="download"]', e => {
            let id = $(e.currentTarget).data('id')
            this.download(id)
        })

    }

    async download(id) {
        this.csvData = [
            ['Id', 'Website', 'Instagram', 'Youtube', 'LinkedIn', 'Twitter', 'Facebook', 'Pinterest', 'TikTok'].join(',')
        ]

        await axios.get(`/crawler/social-link/download/${id}`).then(res => {
            res.data.forEach(element => {
                this.csvData.push([
                    element.id,
                    element.website,
                    element.instagram_accounts,
                    element.youtube_accounts,
                    element.linkedin_accounts,
                    element.twitter_accounts,
                    element.facebook_accounts,
                    element.pinterest_accounts,
                    element.tiktok_accounts,
                ].join(','))
            });
        }).catch(err => {
        })
        download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");
    }



}
