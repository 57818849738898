import download from "downloadjs"

export default class ColorPalette {

    constructor() {
        this.itemList = $('#color-palette-index')

        this.events()
    }


    events(){
        console.log(this.itemList)
        this.itemList.on('click', '[data-type="download"]', e => {
            let id = $(e.currentTarget).data('id')
            this.download(id)
        })

    }

    async download(id) {
        this.csvData = [
            [
                'domain',
                'Color 1',
                'Matches',
                'Color 2',
                'Matches',
                'Color 3',
                'Matches',
                'Color 4',
                'Matches',
                'Color 5',
                'Matches'
            ].join(',')
        ]

        await axios.get(`/crawler/color-palette/download/${id}`).then(res => {
            for (var key of Object.keys(res.data)) {
                let item = [];
                item.push(res.data[key][0].domain)
                res.data[key].forEach(element => {
                    item.push(element.color_code)
                    item.push(element.matches)
                });
                this.csvData.push(item.join(','))
            }
        }).catch(err => {
            console.error(err)
        })
        download(this.csvData.join("\r\n"), 'crawler.csv', "text/csv");
    }



}
